import React from "react";
import {
  Nav,
  Loader,
  TabsCustom,
  Card,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";
import {
  getCurrencyValue,
  getPercentageValue,
  getNumberValue,
} from "../../../utils/general";

import handleSortClick from "../../../utils/handleSortClickV2";

import "../../../index.css";

export default class VVR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: true,
      isLoad: false,
      tabsMenu: ["Statistiques", "Répartition par marque"],
      tabsMenuSub: ["Tous", "4 roues", "2 roues", "Vélos", "Autres"],
      tabsIndex: 0,
      tabsIndexSub: 0,
      // data search
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 2,
      agency: 195,
      dataGeneral: {},
      dataBrands: [],
      order: null,
    };

    this.sortRef = [];
  }

  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.getGeneralData
    );
  };

  componentDidMount() {
    this.setState(
      {
        accessAllowed: checkRights("StatisticsVVR", this.context),
      },
      () => this.getData()
    );
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("pilotage-cabinet/specialties/vvr-maif/get-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              dataBrands: res.data.dataBrands,
              dataGeneral: res.data.dataGeneral,
              isLoad: false,
            });
          } else {
            this.setState({
              dataBrands: [],
              dataGeneral: {},
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false });
        });
    });
  };

  getLines = (name, key, isFull = true) => {
    const isPrice = name.includes("Coût");
    const isPercentage = name.includes("Taux");

    let data = this.state.dataGeneral;

    if (!data[key]) {
      data[key] = [];
    }
    if (!data[`old_${key}`]) {
      data[`old_${key}`] = [];
    }
    if (!data[`evol_${key}`]) {
      data[`evol_${key}`] = [];
    }

    const getLine = (idx) => {
      const names = ["", "4 roues", "2 roues", "vélos", "autres"];

      const v1 = isPrice
        ? getCurrencyValue(data[key][idx])
        : isPercentage
        ? getPercentageValue(data[key][idx])
        : getNumberValue(data[key][idx], name === "Volume" ? 0 : 2);
      const v2 = isPrice
        ? getCurrencyValue(data[`old_${key}`][idx])
        : isPercentage
        ? getPercentageValue(data[`old_${key}`][idx])
        : getNumberValue(data[`old_${key}`][idx], name === "Volume" ? 0 : 2);
      const v3 = getPercentageValue(data[`evol_${key}`][idx]);
      let color = !data[`evol_${key}`][idx]
        ? "bg-orange-light"
        : data[`evol_${key}`][idx] > 0
        ? "bg-green-light"
        : "bg-red-light";

      if (
        [
          "Coût total VRADE",
          "Coût moyen VRADE",
          "Coût total rapport",
          "Coût moyen rapport",
          "Délai mission / contact",
          "Délai mission / dépôt RE",
          "Taux de dossiers avec VRADE minorée",
          "Délai réception mission / examen recycleur",
          "Coût global des honoraires",
          "Coût moyen des honoraires"
        ].includes(name)
      ) {
        if (color === "bg-red-light") {
          color = "bg-green-light";
        } else if (color === "bg-green-light") {
          color = "bg-red-light";
        }
      }

      if (!idx) {
        return (
          <Tr>
            <Th>
              {name} {names[idx]}
            </Th>
            <Th>{v2}</Th>
            <Th>{v1}</Th>
            <Th className={color}>{v3}</Th>
          </Tr>
        );
      } else {
        return (
          <Tr>
            <Td>
              {name} {names[idx]}
            </Td>
            <Td>{v2}</Td>
            <Td>{v1}</Td>
            <Td className={color}>{v3}</Td>
          </Tr>
        );
      }
    };

    return (
      <>
        {getLine(0)}
        {isFull ? getLine(1) : null}
        {isFull ? getLine(2) : null}
        {isFull ? getLine(3) : null}
        {isFull ? getLine(4) : null}
      </>
    );
  };

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  sortData = () => {
    let data = this.state.dataBrands[this.state.tabsIndexSub] || [];

    if (this.state.order && this.state.order.name) {
      data = data.sort((a, b) => {
        return this.state.order.desc
          ? b[this.state.order.name] - a[this.state.order.name]
          : a[this.state.order.name] - b[this.state.order.name];
      });
    }

    return data;
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques VVR" className="mb-20" />
          <ContactRequest page="Statistiques - VVR" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques VVR" className="mb-20" />
          </FadeIn>
          <ShowOptions
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleInputChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            dateStartText="Date de début"
            dateEndText="Date de fin"
            className={"mb-10 mb-20-xl"}
          />
          <TabsCustom>
            <TabsCustomHeader>
              {this.state.tabsMenu.map((element, key) => (
                <TabsCustomMenu
                  noCard
                  disabled={this.props.isLoader}
                  key={key}
                  title={element}
                  active={this.state.tabsIndex === key}
                  onClick={(e) => {
                    this.setState({ tabsIndex: key });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={this.state.tabsIndex === 0}>
                <Card title="Statitistiques">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Nom</Th>
                        <Th>N-1</Th>
                        <Th>N</Th>
                        <Th>Évol. %</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.getLines("Volume", "count")}
                      {this.getLines("Coût total VRADE", "VRADETotal")}
                      {this.getLines("Coût moyen VRADE", "VRADEAverage")}
                      {this.getLines("Coût total rapport", "reportTotal")}
                      {this.getLines("Coût moyen rapport", "reportAverage")}
                      {this.getLines("Âge moyen véhicules", "vehicleAge")}
                      {this.getLines(
                        "Délai mission / contact",
                        "missionContact",
                        false
                      )}
                      {this.getLines(
                        "Délai réception mission / examen recycleur",
                        "missionVisit",
                        false
                      )}
                      {this.getLines(
                        "Délai mission / dépôt RE",
                        "missionReport",
                        false
                      )}
                      {this.getLines(
                        "Coût global des honoraires",
                        "honoraryTotal",
                        false
                      )}
                      {this.getLines(
                        "Coût moyen des honoraires",
                        "honoraryAverage",
                        false
                      )}
                      {this.getLines(
                        "Nombre de dossiers avec PRE",
                        "countPRE",
                        false
                      )}
                      {this.getLines("Taux de dossiers avec PRE", "PRE", false)}
                      {this.getLines(
                        "Nombre dossiers en fraude",
                        "countFraud",
                        false
                      )}
                      {this.getLines(
                        "Taux de dossiers en fraude",
                        "fraud",
                        false
                      )}
                    </Tbody>
                  </Table>
                </Card>
              </TabsCustomItem>
              <TabsCustomItem active={this.state.tabsIndex === 1}>
                <TabsCustom>
                  <TabsCustomHeader>
                    {this.state.tabsMenuSub.map((element, key) => (
                      <TabsCustomMenu
                        noCard
                        disabled={this.props.isLoader}
                        key={key}
                        title={element}
                        active={this.state.tabsIndexSub === key}
                        onClick={(e) => {
                          this.setState({ tabsIndexSub: key });
                        }}
                      />
                    ))}
                  </TabsCustomHeader>
                  <TabsCustomBody noCard>
                    <TabsCustomItem active={true}>
                      <Card
                        className={"mb-10 mb-20-xl"}
                        title="Répartition / marque"
                      >
                        <Table>
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th colSpan={2}>Volumétrie des dossiers</Th>
                              <Th colSpan={2}>Coût total des VRADE</Th>
                              <Th colSpan={2}>Coût moyen des VRADE</Th>
                              <Th colSpan={2}>Coût total des rapports</Th>
                              <Th colSpan={2}>Coût moyen des rapports</Th>
                            </Tr>
                            <Tr>
                              <Th
                                ref={(ref) => (this.sortRef[0] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 0)}
                                nameSort="brand"
                              ></Th>
                              <Th
                                ref={(ref) => (this.sortRef[1] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 1)}
                                nameSort="count"
                              >
                                Volume
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[2] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 2)}
                                nameSort="weight_count"
                              >
                                Poids %
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[3] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 3)}
                                nameSort="VRADETotal"
                              >
                                Montant
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[4] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 4)}
                                nameSort="weight_VRADETotal"
                              >
                                Poids %
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[5] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 5)}
                                nameSort="VRADEAverage"
                              >
                                Montant
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[6] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 6)}
                                nameSort="weight_VRADEAverage"
                              >
                                Poids %
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[7] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 7)}
                                nameSort="reportTotal"
                              >
                                Montant
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[8] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 8)}
                                nameSort="weight_reportTotal"
                              >
                                Poids %
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[9] = ref)}
                                sort
                                onSort={(desc) => this.handleSortClick(desc, 9)}
                                nameSort="reportAverage"
                              >
                                Montant
                              </Th>
                              <Th
                                ref={(ref) => (this.sortRef[10] = ref)}
                                sort
                                onSort={(desc) =>
                                  this.handleSortClick(desc, 10)
                                }
                                nameSort="weight_reportAverage"
                              >
                                Poids %
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {this.sortData().map((element) => (
                              <Tr>
                                <Th>{element.brand}</Th>
                                <Td>{element.count}</Td>
                                <Td>
                                  {getPercentageValue(element.weight_count)}
                                </Td>
                                <Td>{getCurrencyValue(element.VRADETotal)}</Td>
                                <Td>
                                  {getPercentageValue(
                                    element.weight_VRADETotal
                                  )}
                                </Td>
                                <Td>
                                  {getCurrencyValue(element.VRADEAverage)}
                                </Td>
                                <Td>
                                  {getPercentageValue(
                                    element.weight_VRADEAverage
                                  )}
                                </Td>
                                <Td>{getCurrencyValue(element.reportTotal)}</Td>
                                <Td>
                                  {getPercentageValue(
                                    element.weight_reportTotal
                                  )}
                                </Td>
                                <Td>
                                  {getCurrencyValue(element.reportAverage)}
                                </Td>
                                <Td>
                                  {getPercentageValue(
                                    element.weight_reportAverage
                                  )}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Card>
                    </TabsCustomItem>
                  </TabsCustomBody>
                </TabsCustom>
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
          <Loader show={this.state.isLoad} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
