import React from "react";
import { Nav, Loader } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import FadeIn from "react-fade-in";
import moment from "moment";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";
import Indicators from "../../../components/statistics/EGA/Indicators";
import Details from "../../../components/statistics/EGA/Details";
import Evolution from "../../../components/statistics/EGA/Evolution";
import Map from "../../../components/statistics/EGA/Map";
import List from "../../../components/statistics/EGA/List";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";

export default class Ega extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      accessAllowed: false,
      isData: false,
      map: {},
      indicator: {},
      chart: {},
      detail: {},
      list: {},
      folderType: "all",
      folderStatus: "all",
      tmpBody: [],
      dateSinisterStart: moment().format("YYYY-01-01"),
      dateSinisterEnd: moment().format("YYYY-01-01"),
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 0,
      region: 0,
      agency: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({ accessAllowed: checkRights("EGA", this.context) });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      dateSinisterEnd:
        name === "dateSinisterStart" ? value : this.state.dateSinisterEnd,
      dateStart: name === "dateSinisterStart" ? value : this.state.dateStart,
      [name]: value,
    });
  };

  handleInputBlur = () => {};

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: Number(value) }, () => {
      if (name === "network") {
        this.setState({ group: 0, region: 0, agency: 0 });
      } else if (name === "group") {
        this.setState({ region: 0, agency: 0 });
      } else if (name === "region") {
        this.setState({ agency: 0 });
      }
    });
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig({
        url: "pilotage-cabinet/ega/get-data",
        data: {
          dateSinisterStart: this.state.dateSinisterStart,
          dateSinisterEnd: this.state.dateSinisterEnd,
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network ? this.state.network : null,
          group: this.state.group ? this.state.group : null,
          region: this.state.region ? this.state.region : null,
          agency: this.state.agency ? this.state.agency : null,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState(
            {
              map: res.data.data.map,
              indicator: res.data.data.indicator,
              chart: res.data.data.chart,
              detail: res.data.data.detail,
              list: res.data.data.list,
              isData: true,
              isLoad: false,
            },
            () => this.filterBodyCSV(this.state.list.body)
          );
        } else {
          this.setState({
            map: {},
            indicator: {},
            chart: {},
            detail: {},
            list: {
              head: [],
              body: [],
            },
            isData: false,
            isLoad: false,
          });
        }
      });
    });
  };

  filterBodyCSV = (body) => {
    this.setState({ isLoad: true }, () => {
      setTimeout(() => {
        let tmpBody = body.filter(
          (element) =>
            (this.state.folderType === "all" ||
              (this.state.folderType === "R" &&
                element.natureExpertise !== "I" &&
                element.natureExpertise !== "3") ||
              (this.state.folderType === "NR" &&
                element.natureExpertise === "I") ||
              element.natureExpertise === "3") &&
            (this.state.folderStatus === "all" ||
              (this.state.folderStatus === "new" && element.status === 1) ||
              (this.state.folderStatus === "visit" && element.status === 2) ||
              (this.state.folderStatus === "visitOK" && element.status === 3) ||
              (this.state.folderStatus === "closing" && element.status === 4) ||
              (this.state.folderStatus === "error" &&
                element.status === undefined))
        );
        this.setState({ tmpBody, isLoad: false });
      }, 100);
    });
  };

  render() {
    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="EGA" className="mb-20" />
          <ContactRequest page="EGA" />
        </FadeIn>
      ) : (
        <>
          <Nav title="EGA" className="mb-20" />

          <ShowOptions
            className="mb-20"
            handleInputChange={this.handleInputChange}
            handleInputBlur={this.handleInputBlur}
            handleSelectChange={this.handleSelectChange}
            getData={this.getData}
            isDateSinisterStart={true}
            isDateSinisterEnd={true}
            isDateStart={true}
            isDateEnd={true}
            dateSinisterStart={this.state.dateSinisterStart}
            dateSinisterEnd={this.state.dateSinisterEnd}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
          />
          {this.state.isData ? (
            <>
              <Indicators data={this.state.indicator} />
              <Evolution data={this.state.chart} />
              <Details data={this.state.detail} />
              <Map map={this.state.map} />
              <List
                data={{
                  head: this.state.list.head,
                  body: this.state.tmpBody,
                }}
                folderType={this.state.folderType}
                folderStatus={this.state.folderStatus}
                handleInputChange={this.handleInputChange}
                handleDownloadCSV={() =>
                  this.setState({ isDownloadCSV: true, isLoad: false })
                }
                handleDownloadCSV_GAN={() =>
                  this.setState({ isDownloadCSV_GAN: true, isLoad: false })
                }
                getData={this.getData}
              />
            </>
          ) : null}
          <Loader show={this.state.isLoad} />
          {this.state.isDownloadCSV ? (
            <ExportCSV
              header={this.state.list.head}
              data={this.state.list.body}
              separator={";"}
              callback={() =>
                this.setState({
                  isDownloadCSV: false,
                  isLoad: false,
                })
              }
              isDownload={true}
              filename={`dossiers_EGA_${moment().format("YYYY-MM-DD")}.csv`}
            />
          ) : (
            <></>
          )}
          {this.state.isDownloadCSV_GAN ? (
            <ExportCSV
              header={this.state.list.head_GAN}
              data={this.state.list.body}
              separator={";"}
              callback={() =>
                this.setState({
                  isDownloadCSV_GAN: false,
                  isLoad: false,
                })
              }
              isDownload={true}
              filename={`dossiers_EGA_${moment().format("YYYY-MM-DD")}.csv`}
            />
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
}
